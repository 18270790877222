<template>
  <main class="navbar-offset-tablet salon-flow">
    <PageNavigator
      :page-title="$t('salon-days-off.breadcrumbs-list-label')"
      :has-back="true"
      :button-back-link="'/salon'"
      class="d-flex justify-content-between align-items-center border-bottom px-1-5 py-0-8 position-fixed bg-white w-100"
    >
      <template v-slot:action>
        <div>
          <b-link :to="'/salon/days-off/create'" class="btn btn-primary px-4 py-1">
            <span class="font-weight-medium poppins-font">
              {{ $t('salon-days-off.add_day_off_button') }}
            </span>
          </b-link>
        </div>
      </template>
    </PageNavigator>

    <div class="px-1-5 pt-7-5 full-page-height">
      <div class="d-flex flex-wrap h-100 justify-content-center">
        <DayOffsTable
          v-if="!isLoading"
          :items="dayoffs.data"
          @change-sort="changeSort"
          @delete-item="deleteDayOff"
        ></DayOffsTable>
        <CustomPagination
          :data="dayoffs"
          :show-disabled="true"
          @pagination-change-page="changePaginationPage"
        ></CustomPagination>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import DayOffsTable from '@/components/salon/DayOffsTable'
import CustomPagination from '@/components/CustomPagination'
import Spinner from '../../Spinner'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    PageNavigator,
    DayOffsTable,
    CustomPagination,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      payloadData: {
        salon_id: null,
        page: 1,
        sort: '-fullname'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      dayoffs: 'dayoffs/getDayOffs'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      fetchDayOffBySalonId: 'dayoffs/getDayOffBySalonId',
      deleteDayOffById: 'dayoffs/deleteDayOffById',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    setLoadingFlag(val) {
      this.isLoading = val
    },
    setPreviousPageForRequest() {
      if (
        this.dayoffs.data.length === 0 &&
        this.dayoffs.current_page === this.dayoffs.last_page &&
        this.dayoffs.current_page !== 1
      ) {
        this.payloadData.page = this.payloadData.page - 1
      }
    },
    changeSort(val) {
      this.payloadData.sort = val

      this.fetchDayOffBySalonId(this.payloadData)
    },
    changePaginationPage(page = 1) {
      this.payloadData.page = page

      this.fetchDayOffBySalonId(this.payloadData)
    },
    confirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.delete_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    deleteProcess(datOffData) {
      this.setLoadingFlag(true)

      this.deleteDayOffById(datOffData)
        .then(() => {
          if (this.dayoffs.data.length === 0 || this.dayoffs.total === 10) {
            this.setPreviousPageForRequest()
            this.fetchData()
          }

          this.setLoadingFlag(false)

          this.setSuccess({
            messageI18Key: `common.alerts.actions.deleted`,
            successCode: 200,
            successExists: true
          })
        })
        .catch(error => {
          this.setLoadingFlag(false)

          this.setError({
            type: 'delete_dayoff',
            statusCode: error.code,
            messageI18Key: 'common.alerts.actions.global_error'
          })
        })
    },
    deleteDayOff(datOffData) {
      this.confirmMessage()
        .then(value => {
          if (value) {
            this.deleteProcess(datOffData)
          }
        })
        .catch(error => {})
    },
    fetchData() {
      if (this.accountInfo.salon_id) {
        this.payloadData.salon_id = this.accountInfo.salon_id

        this.setLoadingFlag(true)

        this.fetchDayOffBySalonId(this.payloadData)
          .then(() => {
            this.setLoadingFlag(false)
          })
          .catch(() => {
            this.setLoadingFlag(false)
            this.setError({
              errorType: 'dayoff_loading',
              messageI18Key: 'common.alerts.actions.global_error'
            })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
