<template>
  <table class="table table-bordered">
    <thead class="table-bordered">
      <tr>
        <th
          v-for="(th, key) in thList"
          :key="`th-${key}`"
          :style="`width: ${th.width};`"
          :class="{ 'cursor-pointer': th.sortBy }"
          class="text-small roboto-font font-weight-medium py-1-5 pl-1-5 pr-1"
          @click="th.sortBy ? chageSortEmit(th.sortBy) : ''"
        >
          <div class="d-flex justify-content-between">
            {{ $t(th.i18KeyLabel) }}
            <div v-if="th.sortBy" class="d-flex flex-column text-gray position-relative">
              <font-awesome-icon
                :icon="['fas', 'sort-up']"
                class="fa-w-10"
                :class="{ 'text-black': sortedUp(th.sortBy) }"
              ></font-awesome-icon>
              <font-awesome-icon
                :icon="['fas', 'sort-down']"
                class="fa-w-10"
                :class="{ 'text-black': sortedDown(th.sortBy) }"
              ></font-awesome-icon>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, key) in items" :key="`service-${key}`">
        <td class="py-0-7 px-1-5">{{ item.fullname }}</td>
        <td class="py-0-7 px-1-5">{{ item.title }}</td>
        <td class="py-0-7 px-1-5">{{ item.description }}</td>
        <td class="py-0-7 px-1-5">{{ fullDate(item.date, item.start_time) | dateFormat(siteLang) }}</td>
        <td class="py-0-7 px-1-5">{{ fullDate(item.date, item.end_time) | dateFormat(siteLang) }}</td>
        <td class="py-0-7 px-1-5">
          <div class="d-flex action-btns">
            <b-link :to="`/salon/days-off/update/${item.id}`" class="btn btn-checkbox mr-1-5">
              <font-awesome-icon :icon="['fas', 'pencil']" class="fa-w-16"></font-awesome-icon>
            </b-link>

            <button class="btn btn-checkbox" @click="deleteItemEmit(item.id)">
              <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14"></font-awesome-icon>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'DayOffsTable',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortedBy: '',
      thList: [
        {
          i18KeyLabel: 'salon-days-off.table-label.assignee',
          sortBy: 'fullname',
          width: '196px'
        },
        {
          i18KeyLabel: 'salon-days-off.table-label.title',
          sortBy: '',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-days-off.table-label.description',
          sortBy: '',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-days-off.table-label.start_at',
          sortBy: 'date',
          width: '213px'
        },
        {
          i18KeyLabel: 'salon-days-off.table-label.end_at',
          sortBy: 'date',
          width: '213px'
        },
        {
          i18KeyLabel: 'salon-days-off.table-label.action',
          sortBy: '',
          width: '105px'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  methods: {
    fullDate(date, time) {
      return `${date} ${time ? time : ''}`
    },
    sortedUp(val) {
      return this.sortedBy === val
    },
    sortedDown(val) {
      return this.sortedBy === `-${val}`
    },
    deleteItemEmit(itemData) {
      this.$emit('delete-item', itemData)
    },
    chageSortEmit(val) {
      if (this.sortedBy === val) val = `-${val}`

      this.sortedBy = val
      this.$emit('change-sort', val)
    }
  },
  filters: {
    dateFormat(val, locale) {
      let date = val.trim()

      if (val.length === 11) {
        return moment(date)
          .locale(locale)
          .format('LL')
      } else {
        return moment(date)
          .locale(locale)
          .format('LLL')
      }
    }
  }
}
</script>

<style lang="scss">
.salon-flow .table .action-btns button {
  padding: 0.125rem 0;
  width: 1.875rem;
  text-align: center;
}
</style>
